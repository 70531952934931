<template>
  <div
    v-if="!userEntity"
    class="space-y-4 bg-white dark:bg-neutral-900 p-4 py-12 rounded-md shadow"
  >
    <div class="text-center font-medium">
      <p>{{ $t("components.feedAsideUserCard.guestText1") }}</p>
      <p>{{ $t("components.feedAsideUserCard.guestText2") }}</p>
    </div>
    <div class="flex justify-center">
      <NamiButton
        buttonType="primary"
        @buttonClick="
          authStore?.login({
            locale: $route.params.locale as string,
            redirectBackTo: $route.fullPath,
          })
        "
      >
        {{ $t("components.feedAsideUserCard.guestButtonText") }}
      </NamiButton>
    </div>
  </div>
  <div
    v-else-if="userEntity"
    class="bg-white dark:bg-neutral-800 pb-4 rounded-md min-w-0 shadow"
  >
    <TheNuxtLink :to="userLink">
      <AsyncImage
        :src="banner ?? getAbsoluteAssetLink('nami/banner/great-wave.png')"
        class="object-cover rounded-t-md w-full aspect-[7/2]"
      />
      <div class="-translate-y-1/3 px-4 flex gap-2 w-full">
        <UserAvatar
          :user="userEntity"
          :size="64"
          class="flex-shrink-0"
          no-link
        />
        <div class="flex flex-grow min-w-0 flex-col justify-end">
          <div class="grid grid-cols-[1fr_auto] gap-4">
            <div class="flex-grow truncate mb-1">
              <BadgeUsername
                class="font-semibold leading-4"
                :badges
                :official
                :verified
              >
                {{ displayName }}
              </BadgeUsername>
              <div class="text-xs text-neutral-600 dark:text-neutral-400">
                @{{ username }}
              </div>
            </div>
            <div>
              <AsyncData
                v-if="
                  authStore?.user &&
                  userEntity?.id !== authStore.user?.profile.sub
                "
                :handler="checkIfUserFollowsUser"
                id="user-follows-user"
                :server="false"
                :executeWhen="() => !!authStore?.user"
                :watch="userEntity?.id"
              >
                <template v-slot="{ pending, data: isFollowing, refresh }">
                  <NamiButton
                    small
                    :buttonType="isFollowing ? 'secondary' : 'primary'"
                    @click="
                      isFollowing
                        ? unfollow().then(() => refresh())
                        : follow().then(() => refresh())
                    "
                    :disabled="pending || isPendingAction"
                  >
                    {{ isFollowing ? "Unfollow" : "Follow" }}
                  </NamiButton>
                </template>
              </AsyncData>
            </div>
          </div>
        </div>
      </div>
    </TheNuxtLink>
    <div class="flex items-center justify-between px-4">
      <div class="flex gap-4 text-sm">
        <TheNuxtLink
          :to="`/${$route.params.locale}/user/${userEntity?.attributes.username}/following`"
        >
          <span class="font-medium">{{ formatNumber(following ?? 0) }}</span>
          {{ $t("components.feedAsideUserCard.following") }}
        </TheNuxtLink>
        <TheNuxtLink
          :to="`/${$route.params.locale}/user/${userEntity?.attributes.username}/followers`"
        >
          <span class="font-medium">{{ formatNumber(followers ?? 0) }}</span>
          {{
            followers === 1
              ? $t("components.feedAsideUserCard.follower")
              : $t("components.feedAsideUserCard.followers")
          }}
        </TheNuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { User, type UserEntity } from "~/src/api";
import { parseUserForDisplay } from "~/utils/display/user";
import BadgeUsername from "~/components/badge/BadgeUsername.vue";

const props = defineProps<{
  userEntity?: UserEntity;
  following: number;
  followers: number;
}>();

const nuxtApp = useNuxtApp();
const locale = nuxtApp.$i18n.global.locale;
const authStore = nuxtApp.$auth();
const appStore = nuxtApp.$app();

const formatNumber = useNumberFormatter(locale);

const {
  username,
  displayName,
  banner,
  official,
  verified,
  badges,
  href: userLink,
} = parseUserForDisplay(
  computed(() => props.userEntity),
  "small",
);

const isPendingAction = ref(false);

async function checkIfUserFollowsUser() {
  await authStore?.waitUntilRefreshIsComplete();

  if (!authStore?.user || !props.userEntity) {
    return false;
  }

  return await User.checkIfUserFollowsUser(
    authStore?.user.profile.sub,
    props.userEntity.id,
  );
}

async function follow() {
  if (!authStore?.user) {
    return appStore?.openLoginRequiredModal();
  }

  if (!props.userEntity) {
    return;
  }

  isPendingAction.value = true;

  await executeWithNotificationOnError(async () => {
    const token = await authStore?.getToken();
    if (!token) throw new Error("Not logged in.");

    await User.follow(props.userEntity!.id, token);
  }).catch(() => {});

  isPendingAction.value = false;
}

async function unfollow() {
  if (!props.userEntity) {
    return;
  }

  isPendingAction.value = true;

  await executeWithNotificationOnError(async () => {
    const token = await authStore?.getToken();
    if (!token) throw new Error("Not logged in.");

    await User.unfollow(props.userEntity!.id, token);
  }).catch(() => {});

  isPendingAction.value = false;
}
</script>
