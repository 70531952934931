<!-- This is a wrapper component over useAsyncData -->

<template>
  <slot v-if="pending && slots.pending" name="pending" v-bind="{ refresh }" />
  <slot
    v-else-if="error && slots.error"
    name="error"
    v-bind="{ error, refresh }"
  />
  <slot v-else v-bind="{ data, refresh, pending }" />
</template>

<script setup lang="ts" generic="T">
import type { WatchSource } from "vue";

const props = defineProps<{
  id?: string;
  handler: () => Promise<T>;
  server?: boolean;
  lazy?: boolean;
  immediate?: boolean;
  executeWhen?: WatchSource<boolean>;
  watch?: any;
}>();

const emit = defineEmits<{
  (e: "data", v: T | null): void;
  (e: "error", v: Error | null): void;
}>();

const slots = useSlots();

const options = {
  immediate: props.executeWhen ? false : props.immediate,
  server: props.server,
  lazy: props.lazy,
  watch: [() => props.watch],
};

const { data, pending, error, refresh, execute } = props.id
  ? useAsyncData(props.id, props.handler, options)
  : useAsyncData(props.handler, options);

watch(data, (v) => emit("data", v as T | null));
watch(error, (e) => emit("error", e as Error | null));

if (props.executeWhen) {
  watch(props.executeWhen, (v) => v && execute(), { immediate: true });
}
</script>
